import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Carousel from "react-material-ui-carousel";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 280,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  const { title, description, items } = props;

  return (
    <Card className={classes.root} variant="outlined">
      <Carousel indicators={false} autoPlay={false}>
        {items.map((item, index) => (
          <CardMedia
            component="img"
            key={index}
            className={classes.media}
            image={item.image}
            title={item.title}
            alt={item.title}
          />
        ))}
      </Carousel>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}
