import React from "react";

import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Chip,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  tableTitle: {
    paddingLeft: theme.spacing(2),
  },
  icon: {
    "vertical-align": "middle",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

export default function IceCream() {
  const classes = useStyles();

  return (
    <Container>
      <img width="100%" src={process.env.PUBLIC_URL + "/assets/ice-cream/ice_cream_lineup.png"} alt="Ice Cream"></img>
      {sections.map((section) => (
        <Accordion key={section.name}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" component="div">
              {section.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              {section.flavors &&
                section.flavors.map((flavorList) => (
                  <Box className={clsx(classes.tableTitle)} spacing={20} key={section.name + "-" + flavorList.name}>
                    {flavorList.name && (
                      <Typography variant="subtitle1" component="div">
                        {flavorList.name}
                      </Typography>
                    )}
                    {flavorList.items.map((item, index) => (
                      <Chip
                        key={section.name + "-" + flavorList.name + "-" + item.name}
                        label={item.name}
                        style={{
                          backgroundColor: item.color,
                          color: item.textColor,
                          backgroundImage: item.backgroundImage,
                        }}
                        className={classes.chip}
                      />
                    ))}
                  </Box>
                ))}
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Size</TableCell>
                    {/* <TableCell align="right">Price</TableCell> */}
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {section.prices.map((row, index) => (
                    <TableRow key={section.name + "-" + row.name + "-" + index}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right"></TableCell>
                      {/* <TableCell align="right">{isNaN(row.price) ? row.price : "$" + row.price}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}

const iceCream = [
  { name: "Baby", scoops: 1, price: "0.90" },
  { name: "Kiddie", scoops: 2, price: "2.40" },
  { name: "Small", scoops: 3, price: "2.90" },
  { name: "Medium", scoops: 4, price: "3.45" },
  { name: "Large", scoops: 5, price: "4.10" },
  { name: "Pretzel Cone", price: "4.25" },
  { name: "Waffle Cone", price: "4.25" },
  { name: "Chocolate Waffle Cone", price: "4.25" },
  { name: "Waffle Cone", price: "4.25" },
  { name: "Mini Waffle Bowl", price: "2.95" },
  { name: "Waffle Bowl", price: "4.25" },
  { name: "Waffle with Ice Cream", price: "" },
  { name: "Sugar Cone (Kiddie)", price: "2.25" },
  { name: "Sugar Cone (Small)", price: "2.95" },
  { name: "Toppings / Candy / Jimmies", price: "0.60" },
  { name: "Pint", price: "4.00" },
  { name: "Quart", price: "6.00" },
  { name: "1/2 Gallon", price: "9.00" },
  { name: "Chocolate Covered Bananas", price: "1.25" },
  { name: "Ice Cream Sandwich", price: "1.25" },
  // { name: "", price: "5.95 / 6" },
  // { name: "", price: "10.95 / 10" },
];

const waterIce = [
  { name: "Kiddy", price: "1.35" },
  { name: "Small", price: "1.85" },
  { name: "Medium", price: "2.40" },
  { name: "Large", price: "2.75" },
  { name: "Pint", price: "3.00" },
  { name: "Quart", price: "5.00" },
  { name: "1/2 Gallon", price: "8.00" },
];

const sundaes = [
  { name: "Sundae (Small)", price: "3.15" },
  { name: "Sundae (Medium)", price: "4.15" },
  { name: "Sundae (Large)", price: "4.85" },
  { name: "Waffle Sundae", price: "" },
  { name: "Brownie Sundae", price: "4.15" },
  { name: "Funnel Cake (with Ice Cream)", price: "4.25" },
  { name: "Banana Split", price: "7.50" },
  { name: "Sundae of the Month", price: "Prices vary" },
];

const gelatis = [
  { name: "Kid", price: "2.10" },
  { name: "Small", price: "3.10" },
  { name: "Large", price: "4.10" },
];

const floats = [
  { name: "Small", price: "3.75" },
  { name: "Large", price: "4.75" },
];

const milkshakes = [
  { name: "Small", price: "3.95" },
  { name: "Large", price: "4.95" },
];

const arcticSwirls = [
  { name: "Small", price: "4.10" },
  { name: "Large", price: "5.10" },
];

const malts = [
  { name: "Small", price: "4.10" },
  { name: "Large", price: "5.10" },
];

const waterIceFlavors = {
  items: [
    { name: "Cherry", color: "red", textColor: "white" },
    { name: "Sour Apple", color: "green", textColor: "white" },
    { name: "Chocolate", color: "brown", textColor: "white" },
    { name: "Watermelon", color: "#fc6c85", textColor: "white" },
    { name: "Grape", color: "purple", textColor: "white" },
    { name: "Root Beer", color: "#754e3c", textColor: "white" },
    { name: "Orange Cream", color: "orange", textColor: "white" },
    // { name: "Cotton Candy", color: "#ffbcd9" },
    { name: "Cotton Candy", color: "#0ae7ff", textColor: "white" },
    { name: "Blue Raspberry", color: "blue", textColor: "white" },
    { name: "Candy Fish", color: "red", textColor: "white" },
    { name: "Strawberry Lemonade", color: "#fc5a8d", textColor: "white" },
    { name: "Mango", color: "#ffc324", textColor: "white" },
    { name: "Lemon", color: "yellow" },
  ],
};

const iceCreamFlavors = {
  name: "Hand Dipped Flavors (Jack & Jill)",
  items: [
    { name: "Banana", color: "yellow" },
    { name: "Double Fudge Brownie", color: "brown", textColor: "white" },
    { name: "Classic Chocolate", color: "brown", textColor: "white" },
    { name: "Birthday Cake", color: "#f2857e", textColor: "white" },
    { name: "Vanilla Bean", color: "#f3e5ab" },
    { name: "Orange Sherbet", color: "orange", textColor: "white" },
    { name: "Moose Tracks", color: "Brown", textColor: "white" },
    { name: "Bing Cherry", color: "#f2857e", textColor: "white" },
    { name: "Cookies & Cream", color: "sandybrown", textColor: "white" },
    { name: "Chocolate Chip", color: "#6f4e37", textColor: "white" },
    { name: "Strawberry", color: "#fc5a8d", textColor: "white" },
    { name: "Peanut Butter Ripple", color: "chocolate", textColor: "white" },
    { name: "Mint Chocolate Chip", color: "lightgreen", textColor: "white" },
    { name: "Rocky Road", color: "brown", textColor: "white" },
    { name: "Sweet & Salty Caramel Pretzel", color: "#ffd59a" },
    { name: "Black Raspberry", color: "#872657", textColor: "white" },
    { name: "Butter Pecan", color: "#9c7858", textColor: "white" },
    { name: "NSA Mint Chocolate Chip", color: "lightgreen", textColor: "white" },
    { name: "Coffee", color: "#6f4e37", textColor: "white" },
    { name: "Cold Brew Caramel Latte", color: "#ffd59a" },
  ],
};

const softServeFlavors = {
  name: "Soft Serve Flavors",
  items: [
    { name: "Amaretto", color: "brown", textColor: "white" },
    { name: "Banana", color: "yellow" },
    { name: "Blueberry", color: "#4f86f7", textColor: "white" },
    { name: "Blackberry", color: "#32001b", textColor: "white" },
    { name: "Black Cherry", color: "#f2857e", textColor: "white" },
    { name: "Black Raspberry", color: "#872657", textColor: "white" },
    { name: "Butter Pecan", color: "#9c7858", textColor: "white" },
    { name: "Caramel", color: "#ffd59a" },
    { name: "Cheesecake", color: "#f3e5ab" },
    { name: "Coconut", color: "#965a3e", textColor: "white" },
    { name: "Creme de Menthe", color: "#73a18d", textColor: "white" },
    { name: "English Toffee", color: "#996644", textColor: "white" },
    { name: "Espresso", color: "#4e312d", textColor: "white" },
    { name: "Fudge Brownie", color: "brown", textColor: "white" },
    { name: "German Chocolate", color: "brown", textColor: "white" },
    { name: "Green Tea", color: "#d0f0c0" },
    { name: "Lemon", color: "yellow" },
    { name: "Mango", color: "#ffc324", textColor: "white" },
    { name: "Maplenut", color: "#e5a691", textColor: "white" },
    { name: "Orange", color: "orange", textColor: "white" },
    { name: "Peach", color: "#ffdab9" },
    { name: "Peanut Butter", color: "#cd9141", textColor: "white" },
    { name: "Pina Colada", color: "#f2e8cc" },
    { name: "Pineapple", color: "#e6ae25" },
    { name: "Pistachio Nut", color: "#93c572", textColor: "white" },
    { name: "Pomegranate", color: "#584783", textColor: "white" },
    { name: "Pumpkin", color: "#ff7518", textColor: "white" },
    { name: "Rainbow Chill", color: "pink", textColor: "white" },
    { name: "Red Velvet Cake", color: "#86242a", textColor: "white" },
    { name: "Rum", color: "#716675", textColor: "white" },
    { name: "Strawberry", color: "#fc5a8d", textColor: "white" },
    { name: "Tiramisu", color: "#f6e2d6" },
    { name: "Tutti Frutti", color: "pink" },
  ],
};

const sections = [
  { name: "Ice Cream", prices: iceCream, flavors: [iceCreamFlavors, softServeFlavors] },
  { name: "Homemade Water Ice", prices: waterIce, flavors: [waterIceFlavors] },
  { name: "Gelatis", prices: gelatis },
  { name: "Sundaes", prices: sundaes },
  { name: "Arctic Swirls", prices: arcticSwirls },
  { name: "Milkshakes", prices: milkshakes },
  { name: "Malts", prices: malts },
  { name: "Floats", prices: floats },
];
