import React from "react";

import Main from "./Main";
import Page from "./components/Page";

export default function App() {
  return (
    <Page>
      <Main />
    </Page>
  );
}
