import React from "react";

import {
  Container,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  icon: {
    "vertical-align": "middle",
  },
});

export default function Menu() {
  const classes = useStyles();

  return (
    <Container>
      <img width="100%" src={process.env.PUBLIC_URL + "/assets/menu_banner.jpg"} alt="Food"></img>
      {sections.map((section) => (
        <Accordion key={section.name}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" component="div">
              {section.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} key={section.name}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {section.prices.map((row, index) => (
                    <TableRow key={section.name + "-" + row.name + "-" + index}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{"$" + row.price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}

const mealPrices = [
  { name: "Chicken Finger (3) Meal", price: "6.25" },
  { name: "Chicken Wings (5)", price: "6.75" },
  { name: "Personal Pizza", price: "4.99" },
  { name: "Gluten Free Pizza", price: "6.99" },
  { name: "Hot Dog", price: "2.25" },
  { name: "Hot Dog Special", price: "3.75" },
  { name: "Grilled Cheese", price: "2.25" },
  { name: "Grilled Cheese Special", price: "3.75" },
  { name: "Cheesesteak", price: "5.50" },
  { name: "Chicken Cheesesteak", price: "5.50" },
];

const snackPrices = [
  { name: "Corndog", price: "1.75" },
  { name: "Chili (small)", price: "1.49" },
  { name: "Chili (large)", price: "1.99" },
  { name: "Churros", price: "0.99" },
  { name: "", price: "1.78/2" },
  { name: "Soft Pretzels", price: "0.89" },
  { name: "", price: "1.78/2" },
  { name: "Funnel Fries", price: "3.75" },
  { name: "Funnel Cake", price: "2.95" },
  { name: "Apple Cider Doughnuts", price: "0.90" },
  { name: "", price: "4.99/6" },
  { name: "Homemade Brownies", price: "1.00/2" },
  { name: "GF Mini Devil's Food Doughnuts", price: "5.99" },
  { name: "GF Chocolate Chip Cookie", price: "1.49" },
  { name: "GF Chocolate Chip Brownie", price: "1.49" },
];

const sidePrices = [
  { name: "Mozzarella Sticks (6)", price: "5.25" },
  { name: "French Fries", price: "3.25" },
  { name: "Cheese Fries", price: "3.95" },
  { name: "Old Bay Seasoned Fries", price: "3.50" },
  { name: "Onion Rings", price: "3.50" },
  { name: "Nachos & Cheese", price: "2.95" },
];

const drinkPrices = [
  { name: "Coffee", price: "1.60" },
  { name: "Hot Chocolate", price: "2.00" },
  { name: "Coke Fountain Products", price: "0.75/1.00/1.50" },
  { name: "Snapple", price: "1.50" },
  { name: "Stewart's Soda", price: "1.50" },
  { name: "Turkey Hill Drinks", price: "1.50" },
];

const sections = [
  { name: "Meals", prices: mealPrices },
  { name: "Sides", prices: sidePrices },
  { name: "Snacks", prices: snackPrices },
  { name: "Drinks", prices: drinkPrices },
];
