import React from "react";

import { Container } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ExpandingSection from "../components/ExpandingSection";

export default function Groceries() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const initialState = isSmallScreen ? [] : []; //sections.map((section) => section.id);
  const [expanded, setExpanded] = React.useState(initialState);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? [panel].concat(expanded) : expanded.filter((item) => item !== panel));
  };

  return (
    <Container>
      <img width="100%" src={process.env.PUBLIC_URL + "/assets/groceries_banner.jpg"} alt="Groceries"></img>
      {sections.map((section) => (
        <ExpandingSection
          id={section.id}
          name={section.name}
          items={section.items}
          handleChange={handleChange}
          expanded={expanded}
        />
      ))}
    </Container>
  );
}

const sections = [
  {
    id: "fruits",
    name: "Fruits",
    items: [
      { title: "Assorted Fruit", image: process.env.PUBLIC_URL + "/assets/groceries/fruit.jpg" },
      { title: "Apples", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/apples.jpg" },
      { title: "Peaches", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/peachers.jpg" },
      { title: "Blueberries", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/blueberries.jpg" },
      { title: "Strawberries", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/strawberries.jpg" },
      { title: "Watermelon", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/watermelon.jpg" },
      { title: "Yellow Watermelon", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/yellow_watermelons.jpg" },
      { title: "Sugar Babies", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/sugar_babies.jpg" },
      {
        title: "Canaries and Honeydews",
        image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/canaries_honeydews.jpg",
      },
      { title: "Cantaloupe", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/canteloupe.jpg" },
    ],
  },
  {
    id: "vegetables",
    name: "Vegetables",
    items: [
      { title: "Assorted Vegetables", image: process.env.PUBLIC_URL + "/assets/groceries/veggies.jpg" },
      { title: "Tomatoes", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/tomatoes.jpg" },
      { title: "Corn", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/corn.jpg" },
      { title: "Cucumbers", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/cucumbers.jpg" },
      { title: "Squash", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/squash.jpg" },
      { title: "Butternut Squash", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/butternut_squash.jpg" },
      { title: "Eggplant", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/eggplant.jpg" },
      { title: "Hot Peppers", image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/hot_peppers.jpg" },
    ],
  },
  {
    id: "baked-goods",
    name: "Baked Goods",
    items: [
      { title: "Pies", image: process.env.PUBLIC_URL + "/assets/groceries/apple_caramel_nut.jpg" },
      { title: "Breads", image: process.env.PUBLIC_URL + "/assets/groceries/breads.jpg" },
      { title: "Cakes", image: process.env.PUBLIC_URL + "/assets/groceries/cakes.jpg" },
      { title: "Cake Rolls", image: process.env.PUBLIC_URL + "/assets/groceries/cake_rolls.jpg" },
      { title: "Cinnamon Rolls", image: process.env.PUBLIC_URL + "/assets/groceries/cinnamon_rolls.jpg" },
      { title: "Cinnamon Streusel", image: process.env.PUBLIC_URL + "/assets/groceries/cinnamon_streusel.jpg" },
      { title: "Doughnuts", image: process.env.PUBLIC_URL + "/assets/groceries/doughnuts.jpg" },
      { title: "Fudge", image: process.env.PUBLIC_URL + "/assets/groceries/fudge.jpg" },
      { title: "Rolls", image: process.env.PUBLIC_URL + "/assets/groceries/rolls.jpg" },
      { title: "Whoopie Pies", image: process.env.PUBLIC_URL + "/assets/groceries/whoopie_pies.jpg" },
    ],
  },
  {
    id: "jarred-goods",
    name: "Jarred Goods",
    items: [
      { title: "Amish Goods", image: process.env.PUBLIC_URL + "/assets/groceries/amish_jars.jpg" },
      { title: "Dressings", image: process.env.PUBLIC_URL + "/assets/groceries/dressing2.jpg" },
      { title: "Honey", image: process.env.PUBLIC_URL + "/assets/groceries/honey3.jpg" },
      { title: "Pickles", image: process.env.PUBLIC_URL + "/assets/groceries/pickles2.jpg" },
    ],
  },
  {
    id: "dairy-products",
    name: "Dairy Products",
    items: [
      { title: "Butter", image: process.env.PUBLIC_URL + "/assets/groceries/butter.jpg" },
      { title: "Cheeses", image: process.env.PUBLIC_URL + "/assets/groceries/cheese.jpg" },
      { title: "Heavy Cream", image: process.env.PUBLIC_URL + "/assets/groceries/heavy_cream.jpg" },
      { title: "Milk", image: process.env.PUBLIC_URL + "/assets/groceries/milk3.jpg" },
      { title: "Yogurt", image: process.env.PUBLIC_URL + "/assets/groceries/yogurt.jpg" },
    ],
  },
  {
    id: "miscellaneous",
    name: "Miscellaneous",
    items: [
      { title: "Gluten Free Snacks", image: process.env.PUBLIC_URL + "/assets/groceries/gluten_free.jpg" },
      { title: "Herrs Chips", image: process.env.PUBLIC_URL + "/assets/groceries/chips.jpg" },
      { title: "Zerbes Chips", image: process.env.PUBLIC_URL + "/assets/groceries/chips_zerbes.jpg" },
      { title: "Popcorn", image: process.env.PUBLIC_URL + "/assets/groceries/chips3.jpg" },
      { title: "Eggs", image: process.env.PUBLIC_URL + "/assets/groceries/eggs2.jpg" },
      { title: "Salads", image: process.env.PUBLIC_URL + "/assets/groceries/salads2.jpg" },
    ],
  },
  {
    id: "drinks",
    name: "Drinks",
    items: [
      { title: "Soda", image: process.env.PUBLIC_URL + "/assets/groceries/stewarts.jpg" },
      { title: "Turkey Hill Drinks", image: process.env.PUBLIC_URL + "/assets/groceries/turkey_hill.jpg" },
    ],
  },
];
