import React from "react";

import { Container, Grid, Typography, Link, Box, Hidden, Card, CardContent } from "@material-ui/core";
import { Facebook, Instagram } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import MediaCard from "../components/MediaCard";
import InfoCard from "../components/InfoCard";

const useStyles = makeStyles((theme) => ({
  icon: {
    "vertical-align": "middle",
  },
  mobileBanner: {
    "max-width": 345,
  },
  announcementsSm: {
    marginTop: theme.spacing(1),
  },
  announcementsLg: {
    // height: "100%",
  },
}));

export default function Home() {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const announcementsSection = (
    <Grid item xs={12} lg={3} className={isLargeScreen ? classes.announcementsLg : classes.announcementsSm}>
      <Card variant="outlined" className={classes.announcementsLg}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Announcements
          </Typography>
          <Typography variant="body2" component="p">
            Try our Sundae of the Month!
            <br />
            {"June prune"}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Container>
      <Hidden smDown>
        <img width="100%" src={process.env.PUBLIC_URL + "/assets/market_banner2.jpg"} alt="farm market"></img>
      </Hidden>
      <Hidden smUp>
        <img
          width="100%"
          src={process.env.PUBLIC_URL + "/assets/market2.jpg"}
          className={classes.mobileBanner}
          alt="farm market"
        ></img>
      </Hidden>
      <Hidden only="xs" mdUp>
        <img width="100%" src={process.env.PUBLIC_URL + "/assets/market2.jpg"} alt="farm market"></img>
      </Hidden>
      <Grid container justify={"center"} alignItems={"center"} alignContent={"center"}>
      <img
          width="100%"
          src={process.env.PUBLIC_URL + "/assets/announcements/jersey_produce.png"}
          alt="We now have fresh Jersey produce! Come in or check out our Facebook and Instagram to see what's available at this time!"
        ></img>
      </Grid>
      <Grid container spacing={3} justify={"center"} alignItems={"center"} alignContent={"center"}>
        {/* <Hidden lgUp>{announcementsSection}</Hidden> */}
        <Grid container item spacing={3} lg={12}>
          <Grid item xs={12} sm={6} md={4}>
            <MediaCard
              items={bakedGoodsItems}
              title="Fresh Baked Goods"
              description="Fresh baked crumb pies, cream pies, cheese tarts, cake rolls, cookies, gourmet breads, cinnamon buns, brownies, doughnuts, and more!"
            ></MediaCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MediaCard
              items={fruitsVeggiesItems}
              title="Fresh Fruits and Vegetables"
              description="A large assortment of fresh fruits and vegetables! Jersey produce varies with each season."
            ></MediaCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MediaCard
              items={plantsItems}
              title="Bedding/Potted Plants and Gardening Supplies"
              description="A huge variety of bedding & potted plants, decorative pots, gloves, shovels, fertilizers, mulch, soil, and more! Will be available end of April/beginning of May."
            ></MediaCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MediaCard
              items={iceCreamItems}
              title="Ice Cream and Water Ice"
              description="Over 10 refreshing flavors of water ice and more than 30 flavors of ice cream with a variety of toppings. Arctic swirls, milkshakes, sundaes, gelatis, and more! Can't decide? Check out the Sundae of the Month!"
            ></MediaCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MediaCard
              items={groceryItems}
              title="Assorted Groceries"
              description="Jarred goods, dairy products, bread, propane, and more! We are a one stop shop!"
            ></MediaCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MediaCard
              items={easterItems}
              title="Easter Flowers"
              description="Easter flower sale (limited supply)"
            ></MediaCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MediaCard
              items={menuItems}
              title="Menu"
              description="Grab a warm cooked meal - chicken fingers, mozzarella sticks, cheesesteaks, pizza, french fries, hotdogs, & more!"
            ></MediaCard>
          </Grid>
        </Grid>
        {/* <Hidden mdDown>{announcementsSection}</Hidden> */}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InfoCard title="Contact Information">
            <Typography variant="h6" component="h2">
              Address
            </Typography>
            <Typography variant="body2" component="p">
              <Link href="https://goo.gl/maps/XtG3hepiuNP5qQwz9">
                1067 Township Line Road <br />
                Swedesboro, NJ 08085
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Phone: <Link href="tel:+1-856-467-8166">(856) 467-8166</Link>
            </Typography>
            <Typography variant="h6" component="h2">
              Email
            </Typography>
            <Typography variant="body2" component="p">
              <Link href="mailto:ggfarmmarket@gmail.com">ggfarmmarket@gmail.com</Link>
            </Typography>
          </InfoCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InfoCard title="Social Media">
            <Box>
              <Facebook fontSize="large" className={classes.icon} />
              <Link href="https://www.facebook.com/G-G-Farm-Market-123178314407618/" target="_blank">
                Like us on Facebook
              </Link>
            </Box>
            <Box>
              <Instagram fontSize="large" className={classes.icon} />
              <Link href="https://www.instagram.com/ggfarmmarket/" target="_blank">
                Follow us on Instagram
              </Link>
            </Box>
          </InfoCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InfoCard title="Hours">
            <Typography variant="h6" component="h2">
              Sun - Sat
            </Typography>
            <Typography variant="body2" component="p">
              8AM - 9PM
            </Typography>
            <Typography variant="body2" component="p">
              * Open normal hours for all holidays between April & October
            </Typography>
          </InfoCard>
        </Grid>
      </Grid>
    </Container>
  );
}

const bakedGoodsItems = [
  { image: process.env.PUBLIC_URL + "/assets/baked-goods/blueberry.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/baked-goods/banana_cream.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/baked-goods/boston_cream_pie.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/baked-goods/cake_rolls.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/baked-goods/caramel_apple_nut.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/baked-goods/cheese_tarts.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/baked-goods/lemon_meringue.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/baked-goods/shoo_fly.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/baked-goods/sand_tart.jpg" },
];

const fruitsVeggiesItems = [
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/apples.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/blueberries.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/butternut_squash.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/canaries_honeydews.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/canteloupe.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/corn.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/cotton_candy_grapes.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/cucumbers.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/eggplant.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/hot_peppers.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/peachers.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/produce.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/squash.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/strawberries.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/sugar_babies.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/tomatoes.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/veggies.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/watermelon.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/fruit-vegetables/yellow_watermelons.jpg" },
];

const plantsItems = [
  { image: process.env.PUBLIC_URL + "/assets/plants/asters.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/begonia.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/celosia.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/coleus.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/fall_mum.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/fall_mum2.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/fertilizer.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/greenhouse.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/hanging_mum.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/hydrangeas.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/kale.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/mulch_soil.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/pansies.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/poinsettia.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/pots.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/plants/supplies.jpg" },
];

const iceCreamItems = [
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/banana_split.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/april.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/blue.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/cups.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/cherry_waterice.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/ice_cream.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/july.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/lemon_waterice.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/may.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/cc_waterice.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/ss_flavors.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/strawberry_lemonade.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/ice-cream/watermelon.jpg" },
];

const groceryItems = [
  { image: process.env.PUBLIC_URL + "/assets/groceries/butter.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/groceries/chips.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/groceries/chips2.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/groceries/dressing.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/groceries/eggs.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/groceries/heavy_cream.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/groceries/honey.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/groceries/milk.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/groceries/milk2.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/groceries/pickles.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/groceries/rolls.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/groceries/salads.jpg" },
];

const easterItems = [
  { image: process.env.PUBLIC_URL + "/assets/easter/azaleas.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/azaleas2.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/azaleas3.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/daffodils.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/daffodils2.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/easter_mums.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/grape_hy.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/hyacinth_daffodil.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/hyacinths.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/hyacinths2.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/mums.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/mums2.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/mums3.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/tulips.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/tulips2.jpg" },
  { image: process.env.PUBLIC_URL + "/assets/easter/weird_tulips.jpg" },
];

const menuItems = [{ image: process.env.PUBLIC_URL + "/assets/menu/hotdogs.jpg" }];
