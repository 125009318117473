import React from "react";

import {
  Container,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  icon: {
    "vertical-align": "middle",
  },
});

export default function Nursery() {
  const classes = useStyles();

  return (
    <Container>
      <img width="100%" src={process.env.PUBLIC_URL + "/assets/greenhouse_banner.jpg"} alt="Nursery"></img>
      {/* /** Flowers Accordion */}
      <Accordion key={flowersData.name}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" component="div">
              {flowersData.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {flowersData.sections.map((section) => (
              <TableContainer component={Paper} key={section.name}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>{section.name}</TableCell>
                    {/* <TableCell>Price</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {section.items.map((row, index) => (
                    <TableRow key={section.name + "-" + row.name + "-" + index}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      {/* <TableCell align="right">{"$" + row.price}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            ))}
          </AccordionDetails>
        </Accordion>

      {/* /** Other */}
      {sections.map((section) => (
        <Accordion key={section.name}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" component="div">
              {section.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} key={section.name}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    {/* <TableCell align="right">Price</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {section.prices.map((row, index) => (
                    <TableRow key={section.name + "-" + row.name + "-" + index}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      {/* <TableCell align="right">{"$" + row.price}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}

const flowersData = {
  name: "Flowers",
  sections: [
    {
      name: "Variety of Annuals",
      items: [
        { name: "Packs" },
        { name: "Flats" },
        { name: "4.5\" Pots" },
        { name: "6\" Pots" },
        { name: "9\" Planters" },
        { name: "12\" Combination Planters" },
        { name: "Window Boxes" },
        { name: "Terracotta Planters" },
        { name: "8\" Hanging Baskets" },
        { name: "10\" Hanging Baskets" },
        { name: "12\" Combination Hanging Baskets" },
      ]
    },
    {
      name: "Variety of Perennials",
      items: [
        { name: "1 Gallon Pots" },
        { name: "3 Gallon Pots" },
        { name: "Quarts" },
        { name: "6\" Pots" },
      ]
    }
  ]
}

const plantPrices = [
  { name: "4.5\" Pots", price: "" },
  { name: "6\" Pots", price: "" },
  { name: "3 Gallon Pots", price: "" },
  { name: "Packs", price: "" },
  { name: "Flats", price: "" },
];

const miscPrices = [
  { name: "Black Mulch", price: "" },
  { name: "Red Mulch", price: "" },
  { name: "Brown Mulch", price: "" },
  { name: "Topsoil", price: "" },
  { name: "Potting Mix", price: "" },
  { name: "Organic Soil", price: "" },
  { name: "Fertilizers", price: "" },
  { name: "Gardening Tools", price: "" },
  { name: "Plant Saucers", price: "" },
  { name: "Assorted Size/Color Pots", price: "" },
  { name: "Yard Decor", price: "" },
  { name: "Home Decor", price: "" },
];

// 6” Mums
// 9” Mums
// 12” Mums
// 14” Mums
// 10” Hanging Mums
// 9” Kale
// 9” Asters
// 9” Montawk Daisies

const fallPlantPrices = [
  { name: "6\" Mums", price: "" },
  { name: "9\" Mums", price: "" },
  { name: "12\" Mums", price: "" },
  { name: "14\" Mums", price: "" },
  { name: "10\" Hanging Mums", price: "" },
  { name: "9\" Kale", price: "" },
  { name: "9\" Asters", price: "" },
  { name: "9\" Montawk Daisies", price: "" },
];

const sections = [
  // { name: "Flowers", prices: flowerPrices },
  { name: "Vegetable Plants", prices: plantPrices },
  { name: "Miscellaneous", prices: miscPrices },
  { name: "Fall Plants", prices: fallPlantPrices },
];
