import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import Nursery from "./pages/Nursery";
import Groceries from "./pages/Groceries";
import IceCream from "./pages/IceCream";
import Menu from "./pages/Menu";

const Main = () => {
  return (
    <Switch>
      {" "}
      {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path="/" component={Home}></Route>
      <Route exact path="/groceries" component={Groceries}></Route>
      <Route exact path="/icecream" component={IceCream}></Route>
      <Route exact path="/nursery" component={Nursery}></Route>
      <Route exact path="/menu" component={Menu}></Route>
    </Switch>
  );
};

export default Main;
