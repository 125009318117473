import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  header: {
    "background-color": "#f0f0f0",
  },
  media: {
    height: 280,
  },
});

export default function InfoCard(props) {
  const classes = useStyles();
  const { title } = props;

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader title={title} className={classes.header} />
      <CardContent>{props.children}</CardContent>
    </Card>
  );
}
