import React from "react";

import {
  Card,
  CardContent,
  CardMedia,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cardContent: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 150,
  },
}));

export default function ExpandingSection(props) {
  const classes = useStyles();
  const { id, name, items, handleChange, expanded } = props;

  return (
    <Accordion expanded={expanded.includes(id)} onChange={handleChange(id)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={id + "-content"} id={id + "-header"}>
        <Typography className={classes.heading}>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid item xs={6} sm={4} md={2}>
              <Card className={classes.root} variant="outlined">
                <CardMedia
                  component="img"
                  key={index}
                  className={classes.media}
                  image={item.image}
                  title={item.title}
                  alt={item.title}
                />
                <CardContent>
                  <Typography className={classes.cardContent}>{item.title}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
