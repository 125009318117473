import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import Header from "./Header";
import Footer from "./Footer";
import { Divider, Drawer, Link, List, ListItem, Hidden } from "@material-ui/core";

const sections = [
  { title: "Home", url: "/" },
  { title: "Groceries", url: "/groceries" },
  { title: "Ice Cream", url: "/icecream" },
  { title: "Menu", url: "/menu" },
  { title: "Nursery", url: "/nursery" },

];

export default function Page(props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Hidden smUp>
        <Header title="G & G Farm Market" sections={sections} menuFn={handleDrawerOpen} />
        {/* <img src={process.env.PUBLIC_URL + "/assets/logo.jpg"}></img> */}
      </Hidden>
      <Container maxWidth="lg">
        <Hidden xsDown>
          <Header title="G & G Farm Market" sections={sections} menuFn={handleDrawerOpen} />
          {/* <img src={process.env.PUBLIC_URL + "/assets/logo.jpg"}></img> */}
        </Hidden>
        <Hidden smUp>
          <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
            <List>
              {sections.map((section, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <Link href={section.url} color={"inherit"}>
                      {section.title}
                    </Link>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Drawer>
        </Hidden>
        {props.children}
        <Footer />
      </Container>
    </React.Fragment>
  );
}
